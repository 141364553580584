var app = angular.module('app', []);

  app.controller('AddCompanyController', function($scope) {
    var addLocation = false;
  });

  app.controller('EditCompanyController', function($scope) {
    $scope.addLocation = false;
    $scope.addManager = false;
  });

  app.controller('AddKitController', function($scope) {
    $scope.addLocation = false;
    $scope.blue = 0;
    $scope.kit_size = null;

    $scope.templateFilter = function (item) { 
      if(item.exception == true)
      {
        return true;
      }

      if($scope.blue == 1 && item.blue != $scope.blue)
      {
        return false;
      }

      if($scope.kit_size != null && item.size != $scope.kit_size)
      {
        return false;
      }

      return true; 
    };

  });

  app.controller('EditKitController', function($scope) {
    $scope.addProduct = false;
  });

  app.controller('ReviewController', function($scope) {
    $scope.addProduct = false;


    $scope.calculateOrderQuantity = function (kit_quantity, index, input) { 
      if(input > 0){
        if(kit_quantity - input > 0){
          return kit_quantity - input;
        }else{
          return 0;
        }
      }else{
        return kit_quantity;
      }
    };
  });

  app.controller('WelcomeController', function($scope) {
    $scope.addLocation = false;
  });

  app.controller('KitPageController', function($scope) {
    $scope.addProduct = false;


    $scope.changeImage = function (name,path) { 
      $scope.product_name = name;
      $( ".image_swap" ).empty();
      $( ".image_swap" ).append( "<img src='/" + path + "' width='600px' />" );
    };

  });

